import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { createRoot } from 'react-dom/client'
import DailyBibleModel from '../../common/dato-daily-bible-model'
import DevotionModel from '../../common/dato-devotion-model'
import ImageModel from '../../common/dato-image-model'
import ListModel from '../../common/dato-list-model'
import TopicModel from '../../common/dato-topic-model'
import VerseModel from '../../common/dato-verse-model'
import BaseContext from '../components/base-context'
import BasePage from '../components/base-page'
import Jumbotron from '../components/jumbotron'

function IndexPage() {
  const { permissions } = React.useContext(BaseContext)
  const sections = []
  const secondarySections = []
  if (permissions.canView(DailyBibleModel)) {
    sections.push({
      title: 'Daily Bible Digests',
      url: 'db'
    })
  }
  if (permissions.canView(DevotionModel)) {
    sections.push({
      title: 'Devotions',
      url: 'devotions'
    })
    secondarySections.push({
      title: 'Search',
      url: 'search'
    })
    secondarySections.push({
      title: 'Audio',
      url: 'audio'
    })
    secondarySections.push({
      title: 'Translations',
      url: 'translations/devotions'
    })
  }
  if (permissions.canView(ListModel)) {
    sections.push({
      title: 'Lists',
      url: 'lists'
    })
  }
  if (permissions.canView(TopicModel)) {
    sections.push({
      title: 'Topics',
      url: 'topics'
    })
  }
  if (permissions.canView(VerseModel)) {
    sections.push({
      title: 'Verses',
      url: 'verses'
    })
  }
  if (permissions.canView(ImageModel)) {
    secondarySections.push({
      title: 'Images',
      url: 'images'
    })
  }

  if (sections.length > 0) {
    return (
      <Container>
        <Row className={`text-center row-cols-${sections.length}`}>
          {sections.map((section, idx) => {
            return <Col key={idx.toString()}>
              {/* h-100 for equal height in columns */}
              <Card key={section.title} className="h-100">
                <a href={section.url} className="text-decoration-none">
                  <Card.Body className="py-5">
                    <h2>{section.title}</h2>
                  </Card.Body>
                </a>
              </Card>
            </Col>
          })}
        </Row>
        <Row className={`text-center mt-5 row-cols-${secondarySections.length}`}>
          {secondarySections.map((section, idx) => {
            return <Col key={idx.toString()}>
              <Card key={section.title} className="h-100">
                <a href={section.url} className="text-decoration-none">
                  <Card.Body className="py-3">
                    <h4>{section.title}</h4>
                  </Card.Body>
                </a>
              </Card>
            </Col>
          })}
        </Row>
      </Container>
    )
  } else {
    return (
      <Container>
        <Row>
          <Col>
            <Jumbotron className="text-center">
              <h2>Pending Setup</h2>
              <p>Please email Daniel for access.</p>
            </Jumbotron>
          </Col>
        </Row>
      </Container>
    )
  }
}

createRoot(document.querySelector('#react-container')).render(<BasePage hideBreadcrumbs={true} title="Joyverse CMS"><IndexPage /></BasePage>)
